import React from 'react';

import BodyText from '../components/body-text';
import Layout from '../components/layout';
import PageHeading from '../components/page-heading';
import SEO from '../components/seo';

const IndexPage = () => (
  <Layout>
    <SEO title="Home" keywords={['arbitration', 'mediation', 'resolution']} />
    <PageHeading>Welcome</PageHeading>
    <BodyText>
      Ian Anderson has been resolving disputes as an arbitrator and mediator
      since 2004. His focus is on labour and employment disputes, including
      rights-based arbitration, collective bargaining, interest arbitration,
      wrongful dismissal, and health and safety and human rights complaints and
      investigations.
    </BodyText>
    <BodyText>
      As a
      mediator, Ian brings a practical, interest-based problem solving approach.
    </BodyText>
    <BodyText>
      As an arbitrator, Ian’s objective is the fair resolution of disputes through
      procedures which are proportionate to the importance and complexity of the
      issues, and to the amounts involved.
    </BodyText>
  </Layout>
);

export default IndexPage;
